<template>
  <el-row class="table">
    <span class="title">模板管理</span>
    <el-row class="row-bg" :gutter="24">
      <el-col :span="3">
      <el-input
        placeholder="输入搜索模板标题"
        v-model.trim="moduleTitle"
        @keyup.enter.native="search"
      >
      </el-input>
      </el-col>
      <el-col :span="3">
        <el-input
          placeholder="输入搜索创建人姓名"
          v-model.trim="creatorName"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <el-col :span="18">
        <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
        <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
        <el-button type="primary" size="medium" @click="add" class="tb-button">新增模板</el-button>
      </el-col>

    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      style="width: 40%;"
    >
      <el-table-column label="模板标题" align="center" prop="title"></el-table-column>
      <el-table-column label="创建人" align="center" prop="creatorName"></el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="edit(scope.row)">编辑</el-button>
<!--          <el-button size="small" type="primary" plain @click="deleteItem(scope.row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="width: 40%;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- add -->
    <add-scratch-modle
      :show="addMoudleDialog"
      @close="addMoudleDialog=false"
      @scratchmoduleList="scratchmoduleList"
    ></add-scratch-modle>
  </el-row>
</template>

<script>
  import  AddScratchModle  from  '@/components/research/quesbank-manage/addScratchModle'
  import { scratchmoduleList,scratchmoduleDelect } from '@/api/research/scratch'
  import { getToken } from '@/utils/auth'
  export default {
    components: {
      AddScratchModle
    },
    data() {
      return {
        dataList: [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        addMoudleDialog: false,
        moduleTitle: "",
        creatorName: ""
      }
    },
    created() {
      this.scratchmoduleList()
    },
    methods: {
      // 获取模板列表
      async scratchmoduleList() {
        const response = await scratchmoduleList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          title: this.moduleTitle,
          creatorName: this.creatorName,
        })
        this.dataList = response.body.list
        this.pagination.total = response.body.total
      },
      async scratchmoduleDelect(id) {
        const response = await scratchmoduleDelect({
          id: id,
        })
        if (response.state === "success") {
          $msg("删除成功")
          this.scratchmoduleList()
        } else {
          $msg("删除失败")
        }
      },
    // 重置
    reset() {
      this.moduleTitle = ""
      this.creatorName = ""
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1
      this.scratchmoduleList()
    },

    add() {
      this.addMoudleDialog = true
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.scratchmoduleList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.scratchmoduleList()
    },

    // 编辑
    edit(row) {
     const routeData = this.$router.resolve({
        path: '/research/editScratchMould',
        query: {
          file: row.file,
          pageType:"editmoudl",
          moudlid:row.id,
          fileName:row.title,
          token:getToken()
        }
      })
      window.open(routeData.href,"_blank")
    },

    // 删除
    deleteItem(row) {
      this.$confirm('是否删除当前模板', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.scratchmoduleDelect(row.id)
        })
        .catch(() => {
          return false
        })
    }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
</style>
